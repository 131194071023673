var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addOrUpdateHandle()
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.dataList, border: "", "row-key": "id" }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "name",
              "header-align": "center",
              align: "center",
              width: "220",
              label: "名称"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cashOutTaxConfig",
              "header-align": "center",
              align: "center",
              label: "医生提现代扣税配置"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      _vm._l(scope.row.cashOutTaxConfig, function(item, index) {
                        return _c("div", { key: index }, [
                          _c("span", [_vm._v(_vm._s(item.name) + "元 ：")]),
                          _c("span", [_vm._v(_vm._s(item.taxRate))])
                        ])
                      }),
                      0
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              width: "150",
              label: "操作"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "确定要删除吗？" },
                        on: {
                          confirm: function($event) {
                            return _vm.deleteHandle(scope.row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "text",
                              size: "small"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }