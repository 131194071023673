<template>
	<div>
		<el-form :inline="true">
			<el-form-item>
				<el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="dataList" border style="width: 100%;" row-key="id">
			<el-table-column prop="name" header-align="center" align="center" width="220" label="名称"></el-table-column>
			<el-table-column prop="cashOutTaxConfig" header-align="center" align="center" label="医生提现代扣税配置">
				<template slot-scope="scope">
					<div>
						<div v-for="(item, index) in scope.row.cashOutTaxConfig" :key="index">
							<span>{{item.name}}元 ：</span>
							<span>{{item.taxRate}}</span>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column header-align="center" align="center" width="150" label="操作">
				<template slot-scope="scope">
					<el-popconfirm
						title="确定要删除吗？"
						@confirm="deleteHandle(scope.row.id)"
					>
						<el-button  slot="reference" type="text" size="small">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
export default {
	data () {
		return {
			dataList: []
		};
	},
	created () {
		this.getData();
	},
	methods: {
		getData () {
			this.$root.request('getCashoutTax').then((data) => {
				if (data) {
					this.dataList = data;
				}
			});
		},
		addOrUpdateHandle (id) {
			this.$root.go('/mnt/taxOpt', { id });
		},
		deleteHandle (id) {
			this.$root.request('cashoutTaxDel', { id }).then((data) => {
				if (data.ok === 1) {
					this.getData();
				}
			});
		}
	}
};
</script>